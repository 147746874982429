html, body {
    height: 100%;
    background: #000000;
    overflow: hidden;
    -webkit-user-select: none;
}

main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

#gamecanvas {
}

#scoreboard {
    font-family: Arial, Helvetica, sans-serif;
    color: #F5F5F5;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

#levelcontainer{
    display: flex;
    background: #F2F2F2;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 2px 10px 2px 10px;
    color: #000000;
}

