html, body {
  height: 100%;
  -webkit-user-select: none;
  background: #000000;
  overflow: hidden;
}

main {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#scoreboard {
  color: #f5f5f5;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
}

#levelcontainer {
  color: #000000;
  background: #f2f2f2;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  font-size: 16px;
  display: flex;
}

/*# sourceMappingURL=index.fc91eb7a.css.map */
